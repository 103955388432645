import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./Custom.css";
import { setAttributes } from "../../utils";

import Row from "./Row/Row";
// import Dentaliens from "../../components/Dentaliens";

const Custom = ({ rows, advancedSettings, postType, modal }) => {
  const defaultClass = "section custom";
  let attributes = setAttributes(defaultClass, advancedSettings);

  // For use in Dentaliens
  // let dentalien = "";
  // if (attributes.className.search("dentalien") > -1) {
  //   let classes = attributes.className.split(" ");
  //   classes.forEach((cssClass) => {
  //     if (cssClass.search("dentalien-") > -1) {
  //       dentalien = cssClass.substring(cssClass.indexOf("-") + 1);
  //     }
  //   });
  // }

  return (
    <section
      id={attributes.id}
      aria-label={attributes.ariaLabel}
      className={attributes.className}
      data-settings={attributes.settings}
      style={attributes.style}
    >
      {advancedSettings.design.backgroundImage ? (
        <GatsbyImage
          image={
            advancedSettings.design.backgroundImage.imageFile.childImageSharp
              .gatsbyImageData
          }
          className={"section__background"}
          alt={advancedSettings.design.backgroundImage.altText}
          title={advancedSettings.design.backgroundImage.title}
          width={1920}
          height={1080}
          imgStyle={{
            objectPosition: advancedSettings.design.backgroundPosition,
          }}
          objectPosition={advancedSettings.design.backgroundPosition}
        />
      ) : null}
      {attributes.className.search("parallax-hills") !== -1 ? (
        <>
          <div className="parallax__layer gradient" />
          <img
            src="https://admin.fryorthodontics.com/app/uploads/2021/03/kansas-city-orthodontics-skyline-background.png"
            alt="Kansas City Orthodontics Skyline Background"
            loading="lazy"
            className="parallax__layer"
            aria-hidden="true"
          />
          <img
            src="https://admin.fryorthodontics.com/app/uploads/2021/03/kansas-city-orthodontics-skyline.png"
            alt="Kansas City Orthodontics Skyline"
            loading="lazy"
            className="parallax__layer"
            aria-hidden="true"
          />
        </>
      ) : null}
      {attributes.className.search("parallax-circles") !== -1 ? (
        <>
          <img
            className="parallax__layer solid-circles"
            src="https://admin.fryorthodontics.com/app/uploads/2021/02/fry-orthodontics-testimonials-solids.png"
            alt="Circle Background Decoration"
            loading="lazy"
            aria-hidden="true"
          />
          <img
            className="parallax__layer stripe-circles"
            src="https://admin.fryorthodontics.com/app/uploads/2021/02/fry-orthodontics-testimonials-stripes.png"
            alt="Striped Circle Background Decoration"
            loading="lazy"
            aria-hidden="true"
          />
        </>
      ) : null}
      {advancedSettings.design.backgroundOverlay ? (
        <div
          className="section__overlay"
          aria-hidden="true"
          style={attributes.style}
        ></div>
      ) : null}
      <div
        className={[
          "container container-fluid",
          attributes.containerClasses,
        ].join(" ")}
      >
        {rows.map((row, index) => {
          return (
            <Row key={index} row={row} postType={postType} modal={modal} />
          );
        })}
      </div>
      {/* {attributes.className.search("dentaliens") !== -1 ? (
        <Dentaliens dentalien={dentalien} />
      ) : null} */}
    </section>
  );
};

export default Custom;
